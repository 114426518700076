<template>
  <div>
    <el-breadcrumb style="margin: 0 0 10px 10px" separator="/">
      <el-breadcrumb-item>菜品管理</el-breadcrumb-item>
      <el-breadcrumb-item>菜品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">菜品列表</div>
        <!-- <div>
          <el-button
            size="small"
            type="add"
            @click="moban"
            >模板下载</el-button
          >
          <el-button
            size="small"
            type="add"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >导入</el-button
          >
        </div> -->
      </div>
      <div class="int_box">
        <span>产品名称：</span>
        <el-input
          class="int_width"
          v-model="name"
          placeholder="请输入产品名称"
          clearable
        ></el-input>
        <span>餐单日期：</span>
        <el-date-picker
          class="int_width"
          value-format="yyyy-MM-dd"
          v-model="productdate"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
        <el-button size="small" type="cha" icon="el-icon-search" @click="chaxun"
          >查询</el-button
        >
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column label="序号" type="index" width="60">
          </el-table-column>
          <el-table-column
            prop="product_group"
            label="菜品名称"
          ></el-table-column>
          <el-table-column
            label="产品名称"
          >
            <template v-slot="scope">
              <div class="point" @click="detail(scope.row)">
                <el-tag size="medium" type="success">{{scope.row.product_name}}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            width="140"
            prop="production_date"
            label="餐单日期"
          ></el-table-column>
          <el-table-column width="100" label="检测图">
            <template v-slot="scope">
              <div class="imgbox" v-if="scope.row.testing_pic">
                <el-image 
                  v-for="(it, i) in scope.row.testing_pic.split(',')"
                  :key="i"
                  class="imgtable"
                  :src="URL + it"
                  :preview-src-list="[URL + it]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="140" label="现场图">
            <template v-slot="scope">
              <div class="imgbox" v-if="scope.row.pics.length > 0">
                <el-image 
                  v-for="(it, i) in scope.row.pics"
                  :key="i"
                  class="imgtable"
                  :src="URL + it"
                  :preview-src-list="[URL + it]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="real_name" label="导入人"></el-table-column>
          <el-table-column label="状态">
            <template v-slot="scope">
              <div>{{ scope.row.is_del == 1 ? '禁用' : '正常' }}</div>
            </template>
          </el-table-column>

          <el-table-column
            width="160"
            prop="create_at"
            label="导入时间"
          ></el-table-column>
          <el-table-column width="420px" label="操作">
            <template v-slot="scope">
              <el-button size="mini" type="success" @click="detail(scope.row)"
                >详情</el-button
              >
              <el-button
                v-if="scope.row.is_del != 1"
                size="mini"
                type="add"
                @click="addImg(scope.row.id)"
                >添加检测图</el-button
              >
              <el-button
                v-if="scope.row.is_del != 1 && scope.row.testing_pic"
                size="mini"
                type="success"
                @click="addCode(scope.row.id)"
                >生码</el-button
              >
              <el-button
                v-if="scope.row.is_del != 1 && scope.row.codeisset == 1"
                size="mini"
                type="success"
                @click="ewmlist(scope.row.id)"
                >下载二维码</el-button
              >
              <el-button
                v-if="scope.row.is_del != 1"
                size="mini"
                type="warning"
                @click="jinyong(scope.row.id)"
                >禁用</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <!-- 添加菜品 -->
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="900px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 520px; max-width: 800px; overflow: auto">
          <el-form
            ref="form"
            :rules="rules"
            :model="addform"
            label-width="140px"
          >
            <el-form-item label="菜品：" prop="img">
              <el-upload
                style="width: 500px"
                :limit="1"
                :before-upload="beforeAvatarUpload"
                :with-credentials="true"
                :action="URL + 'api/upload'"
                :headers="headers"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">上传</el-button>
              </el-upload>
              <!-- <el-button
                v-if="addform.img"
                size="small"
                type="success"
                @click="yulan"
                >预览</el-button
              > -->
            </el-form-item>
            <el-form-item v-if="importinfo.length > 0">
              <table border="1" align="left" cellpadding="6" cellspacing="0">
                <tr v-for="(it, i) in importinfo" :key="i">
                  <template v-if="i == 0">
                    <th>序号</th>
                    <th v-for="(t, e) in it" :key="e">{{ t }}</th>
                  </template>
                  <template v-if="i != 0">
                    <td>{{ i + 1 }}</td>
                    <td align="left" v-for="(t, e) in it" :key="e">{{ t }}</td>
                  </template>
                </tr>
              </table>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn_box">
          <el-button
            size="small"
            type="cha"
            class="add_btn111"
            @click="addWaste"
            >确定</el-button
          >
          <el-button size="small" @click="handleClose">取消</el-button>
        </div>
      </el-dialog>
      <!-- 详情  -->
      <el-dialog
        title="详情"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible3"
        width="900px"
        :before-close="handleClose3"
      >
        <div class="xian2"></div>
        <div>
          <el-descriptions border>
            <el-descriptions-item
              v-for="(item, i) in items"
              :key="i"
              :label="item.title"
              >{{ item.value }}</el-descriptions-item
            >
            <el-descriptions-item label="检测图">
              <el-image
                v-for="(item, i) in testing_pic"
                :key="i"
                class="imgx"
                :src="URL + item"
                :preview-src-list="[URL + item]"
              >
              </el-image>
            </el-descriptions-item>
            <el-descriptions-item label="现场图">
              <el-image
                v-for="(item, i) in pics"
                :key="i"
                class="imgx"
                :src="URL + item"
                :preview-src-list="[URL + item]"
              >
              </el-image>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-dialog>
      <!-- 添加图片 -->
      <el-dialog
        title="添加检测图"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible1"
        width="900px"
        :before-close="handleClose1"
      >
        <div class="xian2"></div>
        <el-form
          ref="form1"
          :rules="rules1"
          :model="addform1"
          label-width="140px"
        >
          <el-form-item label="上传图片：" prop="testing_pic">
            <el-upload
              multiple
              :limit="5"
              :action="URL + 'api/upload'"
              :headers="headers"
              list-type="picture-card"
              :on-success="handleSuccess1"
              :on-remove="handleRemove1"
              :before-upload="beforeAvatarUpload"
              :on-exceed="handleExceed1"
              :file-list="fileList1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button type="cha" size="small" class="add_btn111" @click="addBtn"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <!-- 生码 -->
      <el-dialog
        title="添加码包"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible2"
        width="900px"
        :before-close="handleClose2"
      >
        <div class="xian2"></div>
        <el-form
          ref="form2"
          :rules="rules2"
          :model="addform2"
          label-width="140px"
        >
          <!-- <el-form-item label="码包名称：" prop="name">
            <el-input class="int" v-model="addform2.name" clearable></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="标识码：" prop="bsm">
            <el-input class="int" v-model="addform2.bsm" clearable></el-input>
          </el-form-item> -->

          <el-form-item label="数量(个)：" prop="num">
            <el-input class="int" v-model="addform2.num" clearable></el-input>
          </el-form-item>
          <el-form-item label="二维码大小(px)：">
            <el-input
              class="int"
              v-model.number="addform2.size"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="标签字体大小：">
            <el-input
              class="int"
              v-model.number="addform2.labelfontsize"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button
            size="small"
            type="cha"
            class="add_btn111"
            @click="addCodeBtn"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  data() {
    return {
      page: 1,
      size: 10,
      name: '',
      productdate: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      dialogVisible: false,
      addform: {
        img: '',
      },
      rules: {
        img: [{ required: true, message: '请选择文件', trigger: 'change' }],
      },
      dialogVisible1: false,
      addform1: {
        testing_pic: [],
        id: '',
      },
      rules1: {
        testing_pic: [
          { required: true, message: '请选择图片', trigger: 'change' },
        ],
      },
      // 编辑用
      id: '',
      text: '',
      URL: '',
      headers: {
        token: '',
      },
      fileList: [],
      importinfo: [],
      fileList1: [],
      // 添加码包参数
      dialogVisible2: false,
      addform2: {
        name: '',
        bsm: '',
        num: '',
        product_id: '',
        size: 200,
        labelfontsize: 15,
      },
      rules2: {
        name: [{ required: true, message: '请输入码包名称', trigger: 'blur' }],
        bsm: [{ required: true, message: '请输入标识码', trigger: 'blur' }],
        num: [{ required: true, message: '请输入数量', trigger: 'blur' }],
      },
      isLoading: false,
      fullPage: true,
      dialogVisible3: false,
      pics: [],
      testing_pic: [],
      items: [],
    }
  },
  created() {
    this.productdate = this.$route.query.time || ''
    console.log(this.$route)
    this.headers.token = window.localStorage.getItem('jd_token') || ''
    this.URL = this.$URL
    this.user()
  },
  components: {
    Loading,
  },
  methods: {
    onCancel() {
      this.$message.error('您取消了数据加载')
    },
    handleSizeChange(val) {
      this.size = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    handleClose() {
      this.dialogVisible = false
      if (this.addform.img) {
        this.delWaste()
      }
      this.$refs.form.resetFields()
      this.fileList = []
    },
    handleClose1() {
      this.dialogVisible1 = false
      this.$refs.form1.resetFields()
      this.fileList1 = []
      this.addform1 = {
        testing_pic: [],
        id: '',
      }
    },
    handleClose2() {
      this.dialogVisible2 = false
      this.$refs.form2.resetFields()
      this.addform2 = {
        name: '',
        bsm: '',
        num: '',
        product_id: '',
        size: 200,
        labelfontsize: 15,
      }
    },
    handleClose3() {
      this.dialogVisible3 = false
      this.info = {}
    },
    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/goods/index', {
        params: {
          name: this.name,
          productdate: this.productdate,
          page: this.page,
          pageNumber: this.size,
        },
      })
      console.log(11, res)
      this.userList = res.data.rows
      this.total = res.data.total
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    // 点击添加按钮，弹出对话框
    add() {
      this.dialogVisible = true
      this.text = '添加'
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 5MB!')
      }
      return isLt2M
    },
    handleSuccess(res) {
      this.addform.img = res.data
      this.yulan()
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    handleRemove(file, fileList) {
      this.delWaste()
    },

    // 预览
    async yulan() {
      const { data: res } = await this.$http.get('api/goods/importinfo', {
        params: {
          name: this.addform.img,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.importinfo = res.data
    },
    // 模板下载
    moban() {
      window.open('/demo.xlsx')
    },
    // 添加
    addWaste() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.isLoading = true
        const { data: res } = await this.$http.get('api/goods/importproduct', {
          params: {
            name: this.addform.img,
          },
        })
        this.isLoading = false
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        this.fileList = []
        this.importinfo = []
        this.addform = {
          img: '',
        }
        this.page = 1
        this.user()
      })
    },
    // 删除excel
    async delWaste() {
      const { data: res } = await this.$http.get('api/goods/delexcel', {
        params: {
          name: this.addform.img,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)
      this.addform.img = ''
      this.importinfo = []
    },
    // 详情
    detail(row) {
      this.dialogVisible3 = true
      this.testing_pic = row.testing_pic ? row.testing_pic.split(',') : []
      this.pics = row.pics
      this.items = row.items
    },
    // 添加图片弹出框
    addImg(id) {
      this.dialogVisible1 = true
      this.addform1.id = id
    },
    handleSuccess1(res) {
      this.addform1.testing_pic.push(res.data)
    },
    handleExceed1(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    handleRemove1(file, fileList) {
      console.log(file, fileList)
      if (fileList.length > 0) {
        this.addform1.testing_pic = []
        fileList.forEach((it) => {
          this.addform1.testing_pic.push(it.response.data)
        })
      }
    },
    // 添加图片
    addBtn() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.addform1.testing_pic = this.addform1.testing_pic.join()
        const { data: res } = await this.$http.post(
          'api/goods/testingpic',
          this.addform1
        )
        if (res.code != 200) {
          this.$message.error(res.msg)
          this.addform1.testing_pic = this.addform1.testing_pic.split(',')
          return
        }
        this.$message.success(res.msg)
        this.dialogVisible1 = false
        this.$refs.form1.resetFields()
        this.fileList1 = []
        this.addform1 = {
          testing_pic: [],
          id: '',
        }
        this.user()
      })
    },
    // 添加码包弹出框
    addCode(id) {
      this.dialogVisible2 = true
      this.addform2.product_id = id
    },
    // 添加码包
    addCodeBtn() {
      this.$refs.form2.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        const { data: res } = await this.$http.post(
          'api/qrcode/create',
          this.addform2
        )
        if (res.code != 200) {
          this.$message.error(res.msg)
          return
        }
        this.qrcode(res.data.packageid)
        this.dialogVisible2 = false
      })
    },
    // 生码
    async qrcode(id) {
      this.isLoading = true
      const { data: res } = await this.$http.get('api/qrcode/status', {
        params: {
          packageid: id,
        },
      })
      if (res.code != 200) return this.$message.error(res.msg)

      if (res.data === 0) {
        this.qrcode(id)
      } else {
        this.isLoading = false
        this.$message.success(res.msg)
        this.$refs.form2.resetFields()
        this.addform2 = {
          name: '',
          bsm: '',
          num: '',
          product_id: '',
          size: 200,
          labelfontsize: 15,
        }
        this.user()
      }
    },
    // 二维码列表
    ewmlist(id) {
      this.$router.push('/codeList?product_id=' + id)
    },
    // 禁用
    jinyong(id) {
      this.$confirm('此操作将禁用该产品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.get('api/goods/disable', {
            params: {
              id: id,
            },
          })
          if (res.code != 200) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.user()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 20px 1px;
  }
  .int_width {
    width: 240px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 300px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }

  .jianzhi {
    font-weight: 700;

    span:nth-child(2) {
      margin-left: 38px;
    }
    span:nth-child(3) {
      margin-left: 170px;
    }
    span:nth-child(4) {
      margin-left: 290px;
    }
  }
  .jianzhi_box {
    margin-bottom: 10px;
  }
  .int_value {
    margin: 0 10px;
  }
  .tableImg {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 5px;
  }
  .imgx {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 5px;
  }
  .point {
    cursor: pointer;
  }
  .imgbox {
    width: 300px;
    display: flex;
    .imgtable {
      display: inline-block;
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  }
  
}
</style>
